import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API_URL from '../api'

const initialState = {
    userData: {},
    isLoading: false,
    error: null,
}

export const getUser = createAsyncThunk(
    'user/data',
    async id => {
        const res = await API_URL.get(`users/users_api/user/${id}/`)
        const data = await res.data
        return data
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = null
            state.userData = action.payload
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
    },
})

export default userSlice.reducer