import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#FF5A01",
        },

    },
    typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        h4: {
            fontSize: 18,
            fontWeight: 500,
            color: "#343434",
            opacity: "80%",
        },
        h5: {
            fontSize: 16,
            fontWeight: 500,
            color: "#5D5D5D",
        },
        h6: {
            fontSize: 16,
            fontWeight: 600,
            color: "#343434",

        },
        subtitle1: {
            fontSize: 10,
            color: "#34343466",
            opacity: 70,
        },
        body1: {
            fontSize: 14,
            color: "#343434",
            opacity: "90%",
        },
        body2: {
            fontSize: 12,
            color: "#343434",
            opacity: "90%",
        },
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.size === 'large' && {
                        borderRadius: 28,
                        padding: '8px, 24px',
                        height: 48,
                    }),
                    ...(ownerState.size === 'medium' && {
                        borderRadius: 20,
                        padding: '8px, 20px',
                        minWidth: "90px",
                        height: 40
                    }),
                    ...(ownerState.size === 'small' && {
                        borderRadius: 20,
                        padding: '8px 16px !important',
                        height: "32px !important",
                        fontSize: "16px",
                        lineHeight: "20px"
                    }),
                    ...(ownerState.color === 'primary' && {
                        background: "#EE3F00",
                        color: "#FFFFFF",
                        "&:hover": {
                            background: "#FF5A01"
                        },
                        ":disabled": {
                            background: "#f3f3f3",
                        }
                    }),
                    ...(ownerState.color === 'secondary' && {
                        background: "transparent",
                        color: "#17191B",
                        border: "1px solid #BCBDBF"
                    }),
                    display: "flex",
                    alignItems: "center"
                }),
            }
        }
    }
});

