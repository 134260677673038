import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API_URL from '../api'


const initialState = {
    allocateBankTransactions: [],
    isLoading: false,
    error: null,
}

export const getAllocateBankTransactions = createAsyncThunk(
    'allocateBankTransactions/List',
    async (id) => {
        const res = await API_URL.post("bankmanagement/user_assign_transactions/", { user_login_id: id })
        const data = await res.data
        return data
    }
)

export const allocateBankTransactionsSlice = createSlice({
    name: 'allocateBankTransactionsList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllocateBankTransactions.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getAllocateBankTransactions.fulfilled, (state, action) => {
            state.isLoading = false
            state.allocateBankTransactions = action.payload
            state.error = null
        })
        builder.addCase(getAllocateBankTransactions.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
    },
})

export default allocateBankTransactionsSlice.reducer