import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { masterData } from "./MasterData";
import {
  ActiveChevronRight,
  ChevronRight,
  LeftArrow,
  RightArrow,
  FourSquares
} from "../assets/icons";
import { getAllReportsLinks } from "../redux/slice/ReportsSlice";
import "./Drawer.css";

const drawerWidth = 250;
const openedMixin = (theme) => ({
  width: drawerWidth,
  height: `calc(100vh - 180px)`,
  marginTop: "134px",
  marginLeft: "8px",
  marginBottom: "10px",
  borderRadius: "0px 0px 10px 0px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto",
  borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  marginTop: "134px",
  marginLeft: "8px",
  height: `calc(100vh - 194px)`,
  padding: "6px",
  borderRadius: "0px 0px 10px 0px !important",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(4)} + 15px)`,
  },
  overflowY: "auto",
  borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const SideMainBar = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};

const SideBarTitleNames = {
  marginLeft: "10px",
  fontFamily: "sans-serif",
  fontWeight: "500",
  fontSize: "12px",
  paddingTop: "5px",
};

const CMTDrawer = ({ children }) => {
  const { userData } = useSelector((state) => state?.user);
  const show = useSelector((state) => state.toggleSideMenu);
  const { reportSidebarData } = useSelector((state) => state?.allReportsDetails);

  const location = useLocation();
  const dispatch = useDispatch();
  // const theme = useTheme();

  const [routes, setRoutes] = useState([]);
  const [rolesCanAccess, setRolesCanAccess] = useState([]);
  const [userRoles, setUserRoles] = useState("");

  const tooltipStyle = {
    zIndex: 9999,
  };

  const handleDrawerToggle = () => {
    dispatch({ type: "HANDLE_ICONS" });
  };

  // Fetch All Reports Links
  useEffect(() => {
    dispatch(getAllReportsLinks());
  }, []);

  useEffect(() => {
    const getPathName = location.pathname?.split("/")?.at(1);
    const routesList = getPathName === "Reports" ? reportSidebarData : masterData[getPathName];
    setRoutes(routesList);

    routesList?.forEach((route) => {
      if (location.pathname === route.path) {
        setRolesCanAccess(route.rolesCanAccess);
      }
    });
  }, [location.pathname, reportSidebarData]);

  // Use useEffect to update userRoles whenever it changes
  useEffect(() => {
    setUserRoles(userData?.user_permissions?.role);
  }, [userData]);

  return (
    <>
      {!!routes?.length && (
        <Drawer variant="permanent" open={show.isOpen}>
          <DrawerHeader
            sx={{ minHeight: "20px !important", marginLeft: "28px" }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ "&:focus": { outline: "none" } }}
            >
              {show.isOpen ? <LeftArrow /> : <RightArrow />}
            </IconButton>
          </DrawerHeader>
          <section className="routes" style={{ padding: "5px 4px" }}>
            {routes?.map((route, index) => {
              const isCurrentPath = route.path === location.pathname;
              if (route?.rolesCanAccess?.includes(userRoles?.toLowerCase())) {
                return (
                  <LightTooltip
                    key={index}
                    placement="right"
                    style={tooltipStyle}
                    arrow
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        border: "solid skyblue 1px",
                        padding: "0",
                      },
                    }}
                  >
                    <NavLink
                      exact
                      to={route.path}
                      key={route.id}
                      className={({ isActive }) =>
                        isActive &&
                          rolesCanAccess?.includes(userRoles?.toLowerCase())
                          ? "link active"
                          : "link"
                      }
                    >
                      <div style={SideMainBar}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {route.icon ?? <FourSquares />}
                          </div>
                          {show.isOpen && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div>
                                <span style={SideBarTitleNames}>
                                  {route.name}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {show.isOpen && (
                          <div style={{ marginLeft: "13px", marginTop: "5px" }}>
                            {isCurrentPath &&
                              rolesCanAccess?.includes(userRoles?.toLowerCase()) ? (
                              <ActiveChevronRight />
                            ) : (
                              <ChevronRight />
                            )}
                          </div>
                        )}
                      </div>
                    </NavLink>
                    <span style={{ padding: "0", margin: "0" }} />
                  </LightTooltip>
                );
              }
            })}
          </section>
        </Drawer>
      )}
    </>
  );
};

export default CMTDrawer;
