import { makeStyles } from "@mui/styles";

//Datagrid pagination styles
export const useStyles = makeStyles({
  pagination: {
    "& .MuiTablePagination-root:last-child": {
      position: "absolute",
      left: "35%",
    },
    "& .MuiTablePagination-selectLabel": {
      display: "none !important",
    },
    "& .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-select.MuiSelect-standard": {
      border: "1px solid #DBDADE",
      padding: "4px 26px 4px 4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      width: "80px",
      height: "34px",
      boxSizing: "border-box",
    },
    "& .MuiSvgIcon-root": {
      right: "12px",
    },
    "& .MuiTablePagination-input": {
      marginLeft: "-10px",
    },
    // To show displayed entries
    "& .MuiTablePagination-displayedRows": {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      color: "#A5A3AE",

      "&::before": {
        content: `"Showing"`,
        padding: "0 4px",
      },
      "&::after": {
        content: `"entries"`,
        padding: "0 4px",
      },
    },
    "& .css-1bwe623-MuiTablePagination-displayedRows": {
      color: "#000000",
    },

    // "& .MuiTablePagination-root:last-child": {
    //   position: "absolute",
    //   left: "35%",
    // },
    // To display selected rows count on checkbox selection
    // '& .css-de9k3v-MuiDataGrid-selectedRowCount': {},
  },
  transactionReceivedDate: {
    height: "37px",
    padding: "0px 10px",
    border: "1px solid #b8b8b8",
    borderRadius: "5px",
    boxSizing: "border-box",
    fontSize: "15px",
    fontWeight: 400,
    marginTop: "3px",
  },
});
