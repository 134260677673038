import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API_URL from '../api'


const initialState = {
    usersList: [],
    isUserListLoading: false,
    error: null,
}

export const getUsersList = createAsyncThunk(
    'user/List',
    async () => {
        const res = await API_URL.get("users/users_api/user/")
        const data = await res.data
        return data
    }
)

export const usersListSlice = createSlice({
    name: 'usersList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsersList.pending, (state) => {
            state.isUserListLoading = true
        })
        builder.addCase(getUsersList.rejected, (state, action) => {
            state.isUserListLoading = false
            state.error = action.error.message
        })
        builder.addCase(getUsersList.fulfilled, (state, action) => {
            state.isUserListLoading = false
            state.usersList = action.payload
            state.error = null
        })
    },
})

export default usersListSlice.reducer