import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import Breadcrumb from "./Components/breadCrumb/Breadcrumb";
import CMTDrawer from "./Components/Sidebar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "./redux/slice/userSlice";

const InlineStyles = {
  Container: {
    display: "flex",
    margin: 8,
    width: "calc(100vw - 16px)",
    height: "calc(100vh - 142px)",
    borderRadius: 8,
    gap: 16,
    boxShadow: "0px 4px 20px 0px #00000029",
    backgroundColor: "#FFFFFF",
  },
};

const PrivateRoutes = () => {
  const token = Cookies.get("refreshToken", "");
  const dispatch = useDispatch();

  useEffect(() => {
    const id = localStorage.getItem("userID");
    dispatch(getUser(id));
  }, []);

  return token ? (
    <div>
      <Breadcrumb />
      <div style={InlineStyles.Container}>
        <CMTDrawer />
        <div
          style={{
            height: `calc(100vh - 180px)`,
            overflowY: "auto",
            overflowX: "auto",
            width: "100%",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoutes;
