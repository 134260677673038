import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import { useEffect } from "react";

const TargetGoalsNewChart = ({ data }) => {
    const percent = (data?.allocated_amount / data?.receivable_amount) * 100 ? (data?.allocated_amount / data?.receivable_amount) * 100 : 0;
    const totalPercent = percent.toFixed(2);

    const CustomLegendLabels = (data) => {
        return (
        <div style={{ position: "absolute", top: "-10px", right: "-10px", display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "14px" }}>
                Received: <span style={{ fontWeight: "bold" }}>{convertToMillions(data?.data?.receivable_amount)}</span>
            </span>
            <span style={{ fontSize: "14px" }}>
                Allocated: <span style={{ fontWeight: "bold" }}>{convertToMillions(data?.data?.allocated_amount)}</span>
            </span>
        </div>
        );
    };

    const convertToMillions = (inputAmount) => {
        if (inputAmount) {
            const inMillions = parseFloat(inputAmount / 1000000).toFixed(2);
            return `${inMillions} M`;
        }
        return inputAmount;
    };

    let roundedValue = Math.round(data?.receivable_amount / 1000000);

    const DisplayTargetGoalsNewChart = (goalroot) => {
        goalroot._logo.dispose();

        var chart = goalroot.container.children.push(am5radar.RadarChart.new(goalroot, {
            panX: false,
            panY: false,
            startAngle: 160,
            endAngle: 380,
        }));

        // Create axis and its renderer
        // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
        var axisRenderer = am5radar.AxisRendererCircular.new(goalroot, {
            innerRadius: -30,
        });

        axisRenderer.grid.template.setAll({
            stroke: goalroot.interfaceColors.get("background"),
            visible: true,
            strokeOpacity: 0.8,
        });

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(goalroot, {
            maxDeviation: 0,
            min: 0,
            max: 100,
            strictMinMax: true,
            tooltip: am5.Tooltip.new(goalroot, {}),
            snapTooltip: true,
            renderer: axisRenderer,
            tooltipText: roundedValue,
        }));

        // Add clock hand
        // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
        var axisDataItem = xAxis.makeDataItem({});

        var clockHand = am5radar.ClockHand.new(goalroot, {
            pinRadius: am5.percent(10),
            radius: am5.percent(65),
            bottomWidth: 20,
        })

        var bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(goalroot, {
            sprite: clockHand,
        }));

        xAxis.createAxisRange(axisDataItem);

        var label = chart.radarContainer.children.push(am5.Label.new(goalroot, {
            tooltip: am5.Tooltip.new(goalroot, {}),
            renderer: axisRenderer,
            visible: false,
        }));

        if (roundedValue > 100) {
            roundedValue = 100;
        }
        axisDataItem.set("value", totalPercent);
        bullet.get("sprite").on("rotation", function () {
            var value = axisDataItem.get("value");
            var fill = am5.color(0x000000);
            // xAxis.axisRanges.each(function (axisRange) {
            //     if (value <= axisRange.get("endValue")) {
            //         fill = axisRange.get("axisFill").get("fill");
            //     }
            // })
            bandsData.forEach(function (band) {
                if (value >= band.lowScore && value <= band.highScore) {
                    fill = am5.color(band.color);
                }
            });
            label.set("text", Math.round(value).toString());

            clockHand.pin.animate({ key: "fill", to: fill, duration: 500, easing: am5.ease.out(am5.ease.cubic) })
            clockHand.hand.animate({ key: "fill", to: fill, duration: 500, easing: am5.ease.out(am5.ease.cubic) })
        });


        chart.bulletsContainer.set("mask", undefined);

        // Create axis ranges bands
        // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Bands
        var bandsData = [{
            color: "#F25022",
            lowScore: 0,
            highScore: 30,
        },
        {
            color: "#FFB900",
            lowScore: 31,
            highScore: 35,
        },
        {
            color: "#7FBA00",
            lowScore: 36,
            highScore: 100,
        }
        ];
        // let totalValue = Math.floor(data?.receivable_amount / 1000000);
        // var bandsData = [
        //     {
        //         color: "#ee3f00",
        //         lowScore: 0,
        //         highScore: Math.floor(totalValue * 0.3), // 30%
        //     },
        //     {
        //         color: "#ebb71b",
        //         lowScore: Math.floor(totalValue * 0.31), // 31%
        //         highScore: Math.floor(totalValue * 0.35), // 35%
        //     },
        //     {
        //         color: "#0da54c",
        //         lowScore: Math.floor(totalValue * 0.36), // 36%
        //         highScore: totalValue, // 100%
        //     }
        // ];

        var tooltip = am5.Tooltip.new(goalroot, {
            getFillFromSprite: false,
            autoTextColor: false,
            getStrokeFromSprite: true
        });

        tooltip.get("background").set("fill", am5.color("#fff"));
        tooltip.label.set("fill", am5.color("#000"));

        am5.array.each(bandsData, function (data) {
            let axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

            axisRange.setAll({
                value: data.lowScore,
                endValue: data.highScore
            });

            axisRange.get("axisFill").setAll({
                visible: true,
                fill: data.color,
                tooltipText: `Received Amount : ${data?.lowScore}M to ${data?.highScore}M`,
                fillOpacity: 0.3,
                tooltip: tooltip,
            });

            axisRange.get("label").setAll({
                inside: true,
                tooltipPosition: "pointer",
                forceInactive: true,
                fill: goalroot.interfaceColors.get("background") && am5.color(0x000000),
                fontWeight: "500",
                maxWidth: 20,
                oversizedBehavior: "truncate"
            });

        });
    };

    useEffect(() => {
        const goalroot = am5.Root.new("tagetStatus");
        DisplayTargetGoalsNewChart(goalroot);

        return (() => {
            goalroot.dispose()
        })
    });

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div id="tagetStatus" style={{ height: 300, width: 350, position: "relative" }}>
                    {data && <CustomLegendLabels data={data} />}
                </div>
                <div style={{
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: 600,
                    color: '#fff',
                    marginTop: "-50px",
                    marginLeft: "80px",
                    marginRight: "80px",
                    backgroundColor: totalPercent >= 36 ? '#7FBA00' : totalPercent >= 31 ? '#FFB900' : '#F25022'
                }}>
                    Goal: {totalPercent} % Allocated
                </div>
            </div>
        </>
    );

}

export default TargetGoalsNewChart;
