import ROUTES from "../utils/routes";
import {
  FourSquares,
  DownloadFile,
  EmptyClipBoard,
  BulletListPoint,
} from "../assets/icons";

export const masterData = {
  Administration: [
    {
      id: 1,
      name: "User",
      path: ROUTES.AUM,
      rolesCanAccess: ["system admin", "manager"],
      icon: <FourSquares />,
    },
    {
      id: 2,
      name: "Lock/Unlock",
      path: ROUTES.ALU,
      rolesCanAccess: ["system admin", "manager"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 3,
      name: "Allocation Status",
      path: "/Administration/AllocationStatus-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 4,
      name: "Bank Exchange Rate",
      path: "/Administration/BankExchange-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 5,
      name: "Bank Information Table",
      path: "/Administration/Bank-Information-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 6,
      name: "Binding Agreements",
      path: "/Administration/Binding-Agreement",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 7,
      name: "Broker Table",
      path: "/Administration/Broker-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 8,
      name: " Correction Type Table",
      path: "/Administration/CorrectionType-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 9,
      name: "Corrective Transfer",
      path: "/Administration/Corrective-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 10,
      name: "Currency",
      path: "/Administration/Currency-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 11,
      name: "Entity Table",
      path: "/Administration/Entity-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <DownloadFile className="administration-icons" />,
    },
    {
      id: 12,
      name: "LOB Table",
      path: "/Administration/LOB-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <EmptyClipBoard className="administration-icons" />,
    },
    {
      id: 13,
      name: "Policy",
      path: "/Administration/Policy-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
    {
      id: 14,
      name: "SCM Partners",
      path: "/Administration/SCM-Table",
      rolesCanAccess: ["system admin", "manager", "lead role"],
      icon: <BulletListPoint className="administration-icons" />,
    },
  ],
  "Bank-Txns": [
    {
      id: 1,
      name: "Statement Upload",
      path: ROUTES.MUSU,
      rolesCanAccess: ["system admin", "manager", "cc processor", "lead role", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 2,
      name: "Uploaded Files",
      path: ROUTES.MUUF,
      rolesCanAccess: ["system admin", "manager", "cc processor", "lead role", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 3,
      name: "Bank Statement Transactions",
      path: ROUTES.MUBST,
      rolesCanAccess: ["system admin", "manager", "cc processor", "lead role", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 4,
      name: "Allocate Bank Transactions",
      path: ROUTES.MUABT,
      rolesCanAccess: ["system admin", "lead role", "cc processor", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 5,
      name: "Cash Tracker",
      path: ROUTES.MUCASHTRACKER,
      rolesCanAccess: ["system admin", "manager", "cc processor", "lead role", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 6,
      name: "Work List Actions",
      path: ROUTES.WLAI,
      rolesCanAccess: ["system admin", "manager", "cc processor", "lead role", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 7,
      name: "Bank Reconciliation",
      path: ROUTES.MUBR,
      rolesCanAccess: ["system admin", "manager", "cc processor", "lead role", "finance", "treasury"],
      icon: <FourSquares />,
    },
  ],
  BDX: [
    {
      id: 1,
      name: "Exception Report",
      path: ROUTES.ExceptionReport,
      rolesCanAccess: ["system admin", "manager", "lead role", "cc processor", "quality analyst", "lead analyst", "onshore lead", "finance", "treasury"],
      icon: <FourSquares />,
    },
    {
      id: 2,
      name: "Premium BDX",
      path: ROUTES.PremiumBDX,
      rolesCanAccess: ["system admin", "manager", "lead role", "cc processor", "quality analyst", "lead analyst", "onshore lead", "finance", "treasury"],
      icon: <FourSquares />,
    },

    // {
    //   id: 1,
    //   name: "Treasury",
    //   path: ROUTES.TreasuryList,
    //   rolesCanAccess: ["system admin", "manager", "lead role"],
    //   icon: <FourSquares />,
    // }
  ],
  Treasury: [
    {
      id: 1,
      name: "Treasury",
      path: ROUTES.TreasuryList,
      rolesCanAccess: ["system admin", "manager", "lead role", "cc processor", "quality analyst", "lead analyst", "onshore lead", "finance", "treasury"],
      icon: <FourSquares />,
    },
  ],
  // Dashboard: [
  //   {
  //     id: 1,
  //     name: "Dashboard",
  //     path: ROUTES.Dashboard,
  //     rolesCanAccess: ["system admin", "manager", "lead role"],
  //     icon: <FourSquares />,
  //   }
  // ],
};
