import React from 'react';
import {
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Typography,
} from "@mui/material";

export default function DisplayReportByReportName() {
  let params = useParams();
  const { report_name } = params;

  const { allReportsLink } = useSelector((state) => state?.allReportsDetails);
  const reportURL = allReportsLink[report_name];

  return (
    <div style={{ width: "100%" }}>
      {reportURL ?
        <iframe title={report_name} width="1140" height="541.25" src={reportURL ?? ""} frameborder="0" allowFullScreen="true"></iframe>
        : <Typography variant="h4" align="center" sx={{ mt: "22%" }}>No Report Found</Typography>}
    </div>
  )
};
