import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useCallback, useState, useMemo, useEffect } from "react";
import { customDateFormat, parseAllocationDate } from "./utils";
import { useWriteOff } from "./hooks";
import { CommonSelect } from "../common";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import UpdateGenericWorkflowDialog from "../Workflow/UpdateGenericWorkflowDialog";

export const inputStyleObj = {
  border: "1px solid #ccc",
  padding: 4,
  paddingLeft: 16,
  fontSize: 13,
  width: "260px",
  borderRadius: 5,
};
export const selectStyleObj = {
  border: "1px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  width: "100%",
  height: 38,
  borderRadius: 5,
};

const History = ({ fixedDetails, history, isApiInProgress }) => {
  const [openUpdateGenericWorkflowDialog, setOpenUpdateGenericWorkflowDialog] =
    useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");
  const [currentTxnWorkflowDetails, setCurrentTxnWorkflowDetails] = useState(
    {}
  );

  const handleUpdateGenericWorkflowDialog = () => {
    setOpenUpdateGenericWorkflowDialog(false);
  };

  return (
    <div className="cash-allocation-dialogue-container">
      <Grid container>
        {fixedDetails.map(({ label, value }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            key={label}
            paddingLeft={i % 2 ? 2 : 0}
            paddingRight={i % 2 ? 0 : 2}
            marginBottom={2}
          >
            <span>{label}</span>
            <span style={{ fontWeight: "bold" }}>: {value}</span>
          </Grid>
        ))}
      </Grid>
      <TableContainer
        sx={{ height: 275, overflow: "auto", position: "relative" }}
      >
        {isApiInProgress ? (
          <CircularProgress
            style={{ position: "absolute", left: "251px", top: "95px" }}
          />
        ) : (
          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  sx={{ fontSize: "15px", fontWeight: 800 }}
                  className="table-cell"
                >
                  Requested On
                </TableCell>
                <TableCell
                  sx={{ fontSize: "15px", fontWeight: 800 }}
                  className="table-cell"
                >
                  Reason
                </TableCell>
                <TableCell
                  className="table-cell p-0"
                  sx={{ fontSize: "15px", fontWeight: 800 }}
                >
                  Amt
                </TableCell>
                <TableCell
                  className="table-cell"
                  sx={{ fontSize: "15px", fontWeight: 800 }}
                >
                  Ccy
                </TableCell>
                <TableCell
                  className="table-cell"
                  sx={{ fontSize: "15px", fontWeight: 800 }}
                >
                  WF Status
                </TableCell>
                <TableCell
                  className="table-cell"
                  sx={{ fontSize: "15px", fontWeight: 800 }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="table-body">
              {history.map((row, index) => (
                <TableRow key={index} className="table-row">
                  <TableCell className="table-cell">
                    {customDateFormat(row?.request_date)}
                  </TableCell>
                  <TableCell className="table-cell">
                    {row?.writeoff_reason}
                  </TableCell>
                  <TableCell className="table-cell">
                    {row?.writeoff_amt}
                  </TableCell>
                  <TableCell className="table-cell">{row?.currency}</TableCell>
                  <TableCell className="table-cell">
                    {row?.workflow?.workflow_status}
                  </TableCell>
                  <TableCell className="table-cell">
                    <IconButton>
                      <SettingsTwoToneIcon
                        onClick={() => {
                          setCurrentWorkflowName(
                            row?.workflow?.workflow_json_data?.workflow_name ??
                              ""
                          );
                          setCurrentTxnWorkflowDetails(row?.workflow ?? {});
                          setOpenUpdateGenericWorkflowDialog(true);
                        }}
                        sx={{ fontSize: "25px", color: "#3b3f44" }}
                      />
                    </IconButton>
                    <IconButton>
                      <FileDownloadOutlinedIcon
                        sx={{ fontSize: "25px", color: "blue" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {openUpdateGenericWorkflowDialog && (
        <UpdateGenericWorkflowDialog
          openUpdateGenericWorkflowDialog={openUpdateGenericWorkflowDialog}
          handleUpdateGenericWorkflowDialog={handleUpdateGenericWorkflowDialog}
          workflowName={currentWorkflowName}
          currentWorkflowDetails={currentTxnWorkflowDetails}
        />
      )}
    </div>
  );
};

const WriteOff = ({ allocation, handleClose, currencies }) => {
  const { history, formData, setFormData, submit, isApiInProgress } =
    useWriteOff(allocation);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showHistory, setShowHistory] = useState(true);

  const fixedDetails = useMemo(
    () => [
      {
        label: "Cash Ref#",
        value: allocation?.cash_reference,
      },
      {
        label: "Txn Date",
        value: customDateFormat(allocation?.bank_txn?.Payment_Receive_Date),
      },
      // {
      //   label: "Request Date",
      //   value: formData.request_date,
      // },
      {
        label: "System",
        value: allocation?.allocation_systemid,
      },
      // {
      //   label: "Approver name",
      //   value: formData.aprover_name,
      // },
      // {
      //   label: "Approved Date",
      //   value: parseAllocationDate(formData.approved_date),
      // },
      {
        label: "Bank A/c#",
        value: allocation?.bank_txn?.bank_details?.account_number,
      },
      {
        label: "Status",
        value: allocation?.allocation_status,
      },
      {
        label: "Receivable Amt",
        value: allocation?.bank_txn?.Receivable_Amount,
      },
    ],
    [allocation]
  );

  useEffect(() => {
    setShowHistory(!!history.length);
  }, [history?.length]);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    handleClose("");
  }, [setIsDialogOpen]);

  const handleSubmitAssing = useCallback(
    async (e) => {
      e.preventDefault();
      await submit();
      setShowHistory(true);
      setFormData("");
    },
    [setIsDialogOpen, submit]
  );

  const handleInputChange = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  const handleReasonDropdown = useCallback(
    (name, value, reason) => {
      setFormData((data) => ({
        ...data,
        [name]: value ?? "",
      }));
    },
    [setFormData]
  );

  const writeOffReasons = [
    { id: 1, label: "Bank Charges" },
    { id: 2, label: "Bad Debt Write Off" },
    { id: 3, label: "Manual Refund Correction" },
    { id: 4, label: "Currency ROE Fluctuation" },
    { id: 5, label: "Residuals and Rounding Errors" },
  ];

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      fullWidth
      // sx={{
      //   "& .MuiDialog-paper": {
      //     maxWidth: "700px",
      //   },
      // }}
    >
      <form onSubmit={handleSubmitAssing}>
        <DialogTitle
          style={{
            background: "#FCFCFC",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Typography sx={{ fontSize: 20 }}>Write Off</Typography>
          <Typography
            sx={{ fontSize: 15, fontWeight: 750, marginLeft: "30px" }}
          >
            Policy#: {allocation?.policy_id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {showHistory ? (
            <History
              setShowHistory={setShowHistory}
              fixedDetails={fixedDetails}
              history={history}
              isApiInProgress={isApiInProgress}
            />
          ) : (
            <>
              <Grid container>
                {fixedDetails.map(({ label, value }, i) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={label}
                    paddingLeft={i % 2 ? 2 : 0}
                    paddingRight={i % 2 ? 0 : 2}
                    marginBottom={2}
                  >
                    <span>{label} </span>
                    <span style={{ fontWeight: "bold" }}>: {value}</span>
                  </Grid>
                ))}
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Write off Reason
                  </p>
                  <CommonSelect
                    placeholder="Select Reason"
                    value={formData?.writeoff_reason ?? ""}
                    handleChange={(event, value, reason) =>
                      handleReasonDropdown(
                        "writeoff_reason",
                        value,
                        reason,
                        event
                      )
                    }
                    options={writeOffReasons.map((option) => option?.label)}
                  />
                </Grid>

                <Grid item xs={12} md={6} paddingLeft={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Amount</p>

                  <Input
                    style={{ ...inputStyleObj }}
                    placeholder="Enter amount here"
                    name="writeoff_amt"
                    value={formData?.writeoff_amt}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.target.value = formData.writeoff_amt || "";
                        return;
                      }
                      handleInputChange(e);
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Currency</p>

                  <Select
                    style={{ ...selectStyleObj }}
                    name="currency"
                    onChange={handleInputChange}
                    value={formData?.currency}
                    placeholder="Select currency code"
                    required
                  >
                    {currencies.map((opt) => (
                      <MenuItem value={opt.currency_code}>
                        {opt.currency_code}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6} paddingLeft={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Comments</p>

                  <TextareaAutosize
                    style={{
                      height: 50,
                      width: "100%",
                    }}
                    name="comments"
                    value={formData?.comments}
                    onChange={handleInputChange}
                    placeholder="Write details here"
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => setShowHistory(!showHistory)}
            color={showHistory ? "warning" : "inherit"}
            variant={showHistory ? "contained" : "outlined"}
            sx={{ borderRadius: 100 }}
          >
            {showHistory ? "Add" : "History"}
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="inherit"
            variant="outlined"
            sx={{ borderRadius: 100 }}
          >
            Close
          </Button>
          {!showHistory && (
            <Button
              variant="contained"
              color="warning"
              sx={{ borderRadius: 100 }}
              type="submit"
              disabled={isApiInProgress}
            >
              Submit 
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WriteOff;
