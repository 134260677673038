import React, { useEffect, useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useStyles } from "./commonPaginationStyles";

export const CommonPaginationContainer = (props) => {
  const { count, setPageState, pageState } = props;

  // State
  const [currentPage, setCurrentPage] = useState(1);

  const classes = useStyles();

  // Update current page when page size changes
  useEffect(() => {
    setCurrentPage(pageState.page);
  }, [pageState.pageSize, pageState.page]);

  const lastPage = () => {
    setPageState((old) => ({
      ...old,
      page: count,
      isLoading: true,
    }));
    setCurrentPage(count);
  };

  const firstPage = () => {
    setPageState((old) => ({
      ...old,
      page: 1,
      isLoading: true,
    }));
    setCurrentPage(1);
  };

  const nextPage = () => {
    if (pageState.page < count) {
      setPageState((old) => ({
        ...old,
        page: old.page + 1,
        isLoading: true,
      }));
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (!(pageState.page === 1)) {
      setPageState((old) => ({
        ...old,
        page: old.page - 1,
        isLoading: true,
      }));
      setCurrentPage(currentPage - 1);
    }
  };

  const pageNumberChange = (value) => {
    setCurrentPage(Number(value));
  };

  const handlePageChange = () => {
    // Trigger API only once even when user performs on blur or on enter (If the current page is same do not call API)
    if (currentPage !== Number(pageState.page)) {
      let parsedValue = currentPage;

      if (parsedValue >= count) {
        parsedValue = count;
      } else if (parsedValue <= 0) {
        parsedValue = 1;
      }

      setCurrentPage(parsedValue);

      setPageState({
        ...pageState,
        page: parsedValue - 0,
        isLoading: true,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e?.keyCode === 13) {
      handlePageChange();
    }
  };

  return (
    <div className={classes.pagination}>
      <button
        onClick={firstPage}
        className={classes.arrowBtn}
        style={
          pageState?.page === 1 || pageState?.page === ""
            ? {
                background: "rgba(239, 239, 239, 0.3)",
                color: "rgba(51, 48, 60, 0.87)",
              }
            : { background: "#fff", color: "rgba(51, 48, 60, 0.87)" }
        }
        disabled={pageState?.page === 1 || pageState?.page === ""}
      >
        <KeyboardDoubleArrowLeftIcon />
      </button>
      <button
        onClick={previousPage}
        disabled={pageState?.page === 1 || pageState?.page === ""}
        className={classes.arrowBtn}
      >
        <ChevronLeftIcon />
      </button>

      <input
        className={classes.input}
        type="number"
        value={currentPage}
        onChange={(e) => pageNumberChange(e?.target?.value)}
        onBlur={(e) => handlePageChange()}
        style={{ width: pageState?.page > 99 ? "65px" : "30px" }}
        onKeyDown={(e) => handleKeyDown(e)}
      />

      <span
        className={classes.text}
        style={{ color: "rgba(51, 48, 60, 0.87)" }}
      >
        of
      </span>
      <button
        disabled={pageState?.page === count}
        className={classes.countBtn}
        onClick={lastPage}
      >
        {count}
      </button>
      <button
        disabled={pageState?.page === count || !(pageState?.page < count)}
        onClick={nextPage}
        className={classes.arrowBtn}
      >
        <ChevronRightIcon icon="tabler:chevron-right" />
      </button>
      <button
        disabled={pageState?.page === count}
        onClick={lastPage}
        style={
          pageState?.page === count
            ? {
                background: "rgba(239, 239, 239, 0.3)",
                color: "rgba(51, 48, 60, 0.87)",
              }
            : { color: "rgba(51, 48, 60, 0.87)" }
        }
        className={classes.arrowBtn}
      >
        <KeyboardDoubleArrowRightIcon icon="tabler:chevrons-right" />
      </button>
    </div>
  );
};
