import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Switch,
  TextField,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ROUTES from "../../../utils/routes";
import { ROLES } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { getDivisions } from "../../../redux/slice/divisionsSlice";
import { getUsersList } from '../../../redux/slice/usersListSlice';
import API from "../../../redux/api";
import { CommonSelect } from "../../../Components/common";
import { CommonButton } from "../../../Components/common/commonButton";

const addUserCancel = {
  width: "90px",
  height: "40px",
  backgroundColor: "white",
  borderRadius: "50px",
  color: "black",
  marginRight: "10px",
};

const addUserSave = {
  backgroundColor: "#FF5A01",
  borderRadius: "20px",
  padding: "8px 16px",
  height: "40px",
  width: "90px",
  color: "white",
  textTransform: "none",
};
export default function ManageUser({ type }) {
  // Selectors
  const { usersList } = useSelector((state) => state?.usersList);
  const { divisions } = useSelector((state) => state?.divisions);
  const { manageUser } = useSelector((state) => state?.manageUser);

  // Hooks
  const navigate = useNavigate();

  // Local State
  const [managers, setManagers] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [formData, setFormData] = useState({
    userName: "",
    department: "",
    email: "",
    reportingManager: "",
    organisationalRole: "",
    division: "",
    radioButton: true,
    superAdmin: false,
    supervisor: false,
    creditController: false,
    agent: false,
    status: "Active",
  });

  const title = { view: "View User", add: "Add User", edit: "Edit User" }[type];

  const dispatch = useDispatch();

  useEffect(() => {
    if (usersList?.length <= 0) {
      dispatch(getUsersList());
    }
    dispatch(getDivisions());

    if (type !== "add")
      setFormData({
        userName: manageUser?.user_name,
        department: manageUser?.department,
        email: manageUser?.email,
        reportingManager: manageUser?.reporting_manager?.user_name,
        organisationalRole: manageUser?.role,
        division: manageUser?.division?.entity_divisions,
        status: manageUser?.status,
        superAdmin: false,
        supervisor: false,
        creditController: false,
        agent: false,
      });
  }, []);

  useEffect(() => {
    if (usersList?.length > 0) {
      setManagers(
        usersList?.filter(
          (user) => user?.role === "Manager" && user?.status === "Active"
        )
      );
    }
  }, [usersList]);

  const requiredFields = [
    "userName",
    "department",
    "email",
    "organisationalRole",
    "division",
  ];
  const [errors, setErrors] = useState({
    email: "",
  });

  useEffect(() => {
    setIsSaveDisabled(
      !(requiredFields.every((field) => !!formData[field]) && !errors?.email)
    );
  }, [formData, errors]);

  const handleEmailBlur = () => {
    if (type === "add" || type === "edit") validateEmail();
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "email") value = value.trim();
    setFormData({ ...formData, [name]: value });

    if (name === "email" && value.includes(".com")) validateEmail(value);
  };

  const handleDropdownChange = (name, value, reason, event) => {
    if (reason === "clear") {
      setFormData({ ...formData, [name]: "" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSwitchChange = () => {
    setFormData({
      ...formData,
      status: formData.status === "Active" ? "Inactive" : "Active",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reportingManagerInfo = managers?.filter(
      (item) => item?.user_name === formData.reportingManager
    );
    const reportingManagerId = reportingManagerInfo[0]?.id;

    const bankDivisionInfo = divisions?.filter(
      (item) => item?.entity_divisions === formData.division
    );
    const bankDivisionId = bankDivisionInfo[0]?.id;

    try {
      const addData = {
        user_name: formData.userName,
        email: formData.email,
        role: formData.organisationalRole,
        status: formData.status,
        department: formData.department,
        reporting_manager:
          type === "add"
            ? reportingManagerId
            : managers?.filter(
                (manager) => manager?.user_name === formData.reportingManager
              )[0],
        division:
          type === "add"
            ? bankDivisionId
            : divisions?.filter(
                (division) => division?.entity_divisions === formData.division
              )[0],
      };

      const response =
        type === "add"
          ? await API.post(`users/users_api/user/`, addData)
          : await API.patch(`users/users_api/user/${manageUser?.id}/`, {
              ...addData,
            });
      if (response.status === 200) {
        toast.success(
          type === "add" ? "User added successfully" : "Successfully updated",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        setErrors({ ...errors, email: "" });
        navigate(ROUTES.AUM);
      } else {
        toast.error("Failed to add user", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error("Failed to add user", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const validateEmail = (currentEmail = null) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let currentFormEmail = currentEmail ?? formData?.email;
    const isValid = emailRegex.test(currentFormEmail);
    if (isValid) {
      // check if email is already exists for both add and edit user
      let emails = [];
      if (type === "add") {
        emails = usersList?.map((user) => user?.email?.toLowerCase());
      } else if (type === "edit") {
        emails = usersList?.map((user) => {
          if (user.id !== manageUser?.id) {
            return user?.email?.toLowerCase();
          }
          return null;
        });
        emails = emails.filter((item) => item != null);
      }
      emails?.includes(currentFormEmail.toLowerCase())
        ? setErrors({ ...errors, email: "Email already available" })
        : setErrors({ ...errors, email: "" });
    } else {
      setErrors({ ...errors, email: "Invalid email format" });
    }
  };

  const ManageUserBox = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const ManageUserTitle = {
    margin: "0px",
    fontWeight: "600",
    color: "#FF5A01",
    marginLeft: "5px",
  };

  const ManageUserFormTitle = {
    margin: "0px",
    padding: "10px",
    fontWeight: "600",
  };

  const ManageCloseIcon = {
    zIndex: 1,
    color: "red",
    marginRight: "20px",
  };

  const ForminputLabelUsername = {
    paddingBottom: "5px",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
  };

  const ForminputLabelStatus = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "30px",
  };

  const ForminputLabelDepartment = {
    paddingBottom: "5px",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
  };

  const ForminputLabelEMail = {
    paddingBottom: "5px",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
  };

  const ForminputLabelReportingManager = {
    paddingBottom: "5px",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
  };

  const ForminputLabelOrganisationalRole = {
    paddingBottom: "5px",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
  };

  const ForminputLabelDivision = {
    paddingBottom: "5px",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
  };

  const DivBarButtons = {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
    paddingBottom: "10px",
  };

  return (
    <div style={{ boxShadow: 2, marginTop: 10 }}>
      <div style={ManageUserBox}>
        <p style={ManageUserTitle}>{title}</p>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => navigate(ROUTES.AUM)}
          aria-label="close"
          sx={ManageCloseIcon}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "inline-block", width: "98%" }}
      >
        <Box sx={{ boxShadow: 6, marginTop: "10px", marginLeft: "5px" }}>
          <p style={ManageUserFormTitle}>Basic Information</p>
          <hr style={{ marginTop: "0px" }} />

          <Grid container spacing={3} sx={{ padding: "10px" }}>
            <Grid item xs={3}>
              <InputLabel htmlFor="dropdown1" style={ForminputLabelUsername}>
                User Name
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Enter the User Name"
                // label="Textfield 1"
                value={formData.userName}
                onChange={handleChange}
                name="userName"
                size="small"
                sx={{ fontSize: "13px" }}
                disabled={type === "view"}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <div style={ForminputLabelStatus}>
                  <p style={{ margin: "0", padding: "0", marginRight: "10px" }}>
                    Status:
                  </p>
                  <Switch
                    checked={formData.status === "Active"}
                    onChange={handleSwitchChange}
                    name="radioButton"
                    disabled={type === "view"}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor="dropdown1" style={ForminputLabelDepartment}>
                Department
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Enter the Department"
                value={formData.department}
                onChange={handleChange}
                name="department"
                size="small"
                sx={{ fontSize: "13px" }}
                disabled={type === "view"}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel htmlFor="dropdown1" style={ForminputLabelEMail}>
                E-Mail
              </InputLabel>
              <TextField
                fullWidth
                placeholder="Enter the email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleEmailBlur}
                name="email"
                size="small"
                error={!!errors.email}
                helperText={errors.email}
                disabled={type === "view"}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor="dropdown1"
                style={ForminputLabelReportingManager}
              >
                Reporting Manager
              </InputLabel>
              <FormControl fullWidth>
                <CommonSelect
                  name="reportingManager"
                  placeholder="Choose a reporting manager"
                  value={formData.reportingManager}
                  handleChange={(event, value, reason) =>
                    handleDropdownChange(
                      "reportingManager",
                      value,
                      reason,
                      event
                    )
                  }
                  options={managers?.map((option) => option?.user_name)}
                  disabled={type === "view"}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor="dropdown1"
                style={ForminputLabelOrganisationalRole}
              >
                Organisational Role
              </InputLabel>
              <FormControl fullWidth>
                <CommonSelect
                  name="organisationalRole"
                  placeholder="Choose a organisational role"
                  value={formData.organisationalRole}
                  handleChange={(event, value, reason) =>
                    handleDropdownChange(
                      "organisationalRole",
                      value,
                      reason,
                      event
                    )
                  }
                  options={ROLES}
                  disabled={type === "view"}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor="dropdown1" style={ForminputLabelDivision}>
                Division
              </InputLabel>
              <FormControl fullWidth>
                <CommonSelect
                  name="division"
                  placeholder="Choose a division"
                  value={formData?.division}
                  handleChange={(event, value, reason) =>
                    handleDropdownChange("division", value, reason, event)
                  }
                  options={divisions?.map((option) => option?.entity_divisions)}
                  disabled={type === "view"}
                />
              </FormControl>
            </Grid>
            {/* Switch button */}
          </Grid>
        </Box>
        <div style={DivBarButtons}>
          <CommonButton
            text=" Cancel"
            handleClick={() => {
              setErrors({ ...errors, email: "" });
              navigate(ROUTES.AUM);
            }}
            sx={addUserCancel}
          />
          {type !== "view" && (
            <CommonButton
              text="Save"
              type="submit"
              disabled={isSaveDisabled}
              sx={addUserSave}
              hoverColor="#FF5A01"
            />
          )}
        </div>
      </form>
    </div>
  );
}
