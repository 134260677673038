import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  FormLabel,
  Radio,
  DialogContentText,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DialogueContainer from "./DialogueContainer";
import { allocationStatusList, policyActions, systemIdList } from "./constants";
import AllocationDetails from "./AllocationDetails";
import { doUiCalculations, doUiValidations } from "./utils";
import { toast } from "react-toastify";
import { useCurrencies } from "./hooks";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CommonButton } from "../common/commonButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import instance from "../../redux/api";
import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';


const selectStyle = {
  width: "152px",
  height: "25px",
  padding: "0px",
};

const originalAmountIconStyle = {
  color: "FF5A01",
  background: "transparent",
  cursor: "pointer",
  transform: "rotate(90deg)",
};

const AllocationsTable = ({
  allocations,
  setAllocations,
  validationStatus,
  handleSave,
  transaction,
  selectedRowIndex,
  setSelectedRowIndex,
  apiProgressBar,
  setIsEditingRecord,
  policyRef,
  userData
}) => {
  const [validations, setValidations] = useState({});
  const [openDialogueId, setOpenDialogueId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [dialogueAllocation, setDialogueAllocation] = useState(null);
  const [originalAmountSelectionDialog, setOriginalAmountSelectionDialog] =
    useState(false);

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [dialogCommentsValue, setDialogCommentsValue] = useState(null);
  const [currentCommentsIndex, setCurrentCommentsIndex] = useState(null);

  const [currentCashAllocationIndex, setCurrentCashAllocationIndex] =
    useState(-1);
  const [currentOriginalAmountsList, setCurrentOriginalAmountsList] = useState(
    []
  );
  const [currentPolicyPk, setCurrentPolicyPk] = useState(0);
  const [currentRowMode, setCurrentRowMode] = useState("EDIT");
  const [policyRefNo, setPolicyRefNo] = useState(policyRef);
  const [viewedRowIndex, setViewedRowIndex] = useState(null);
  const currencies = useCurrencies();

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuId(id);
  };
  const handleClose = (id, allocation) => {
    setOpenDialogueId((prev) => (prev === id ? "" : id));
    setAnchorEl(null);
    setMenuId(null);
    setDialogueAllocation(allocation);
  };

  // Function to handle individual checkbox change
  const handleCheckboxChange = (index, mode) => {
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      initialState: JSON.stringify(newAllocations[index]),
    };
    setPolicyRefNo(newAllocations[index].id);
    setAllocations(newAllocations);
    setSelectedRowIndex(index);
    setCurrentRowMode(mode);

    // Set the viewed row index when mode is "VIEW"
    if (mode === "VIEW") {
      setViewedRowIndex(index);
    } else {
      setViewedRowIndex(null); // Reset when not in view mode
    }
    return index; // Update the selected row index after all other state updates
  };

  // Function to determine if a row field should be disabled
  const handleRowFieldDisable = (currentIndex) => {
    return allocations[currentIndex]?.disabled || (currentRowMode === "EDIT" && selectedIndex !== currentIndex) || (viewedRowIndex !== null && viewedRowIndex !== currentIndex);
  };

  const cancelSelection = () => {
    if (allocations[selectedRowIndex].initialState)
      allocations[selectedRowIndex] = JSON.parse(
        allocations[selectedRowIndex].initialState
      );
    delete allocations[selectedRowIndex].initialState;

    setAllocations([...allocations]);
    setSelectedRowIndex(-1);
    setHasChanges(false);
    setValidations({});
  };

  const saveSelection = () => {
    apiProgressBar = true;
    const allocation_status = allocations[selectedRowIndex]?.allocation_status;
    const comment = allocations[selectedRowIndex]?.comment;

    if (allocation_status?.startsWith("Query") && !comment) {
      toast.error("Comments are required for Query status", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      apiProgressBar = false;
      return;
    }
    if (!hasChanges) {
      toast.warning("No changes to save", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      apiProgressBar = false;
      return;
    }
    if (Object.keys(validations).length) {
      toast.error(
        <span>
          Please fix the errors in the row before save
          <ul>
            {Object.values(validations).map((v) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        </span>,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        }
      );
      apiProgressBar = false;
      return;
    }
    handleSave(allocations[selectedRowIndex]);
    apiProgressBar = false;
  };

  const errorMessages = {
    bank_roe: "Bank ROE cannot be negative.",
  };

  const handleInputChange = (index, colName, value) => {
    if (["bank_roe"].includes(colName) && value < 0) {
      toast.error(errorMessages[colName], {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    const updatedRows = [...allocations];
    const row = updatedRows[index];
    row[colName] = value;
    doUiCalculations(colName, index, updatedRows);
    setAllocations(updatedRows);
    const newValidations = doUiValidations(row, validations);
    setValidations(newValidations);
    const changedRow = { ...row };
    delete changedRow.initialState;
    setHasChanges(JSON.stringify(changedRow) !== row.initialState);
  };

  const shouldShow = (item, row) => {
    switch (item.id) {
      case "correction-types": {
        return row.allocation_status !== "Allocated";
      }
      case "corrective-transfer": {
        return !!row.unallocated_amt;
      }
      default:
        return true;
    }
  };

  const handleCommentsOpenDialog = (
    currentRowIndex,
    currentRowCommentsData
  ) => {
    setOpenCommentsDialog(true);
    setDialogCommentsValue(currentRowCommentsData);
    setCurrentCommentsIndex(currentRowIndex);
  };

  const handleCloseDialogs = () => {
    setOpenCommentsDialog(false);
    setDialogCommentsValue(null);
    setCurrentCommentsIndex(null);
  };

  const handleDialogInputChange = (e) => {
    setDialogCommentsValue(e.target.value);
  };

  const handleSaveComments = () => {
    setOpenCommentsDialog(false);
    handleInputChange(currentCommentsIndex, "comment", dialogCommentsValue);
  };
  const selectedIndex = selectedRowIndex;
  const singleSelect = selectedIndex >= 0;

  const handleOpenOriginalAmount = (index, originalAmountsList, policyPk) => {
    setCurrentOriginalAmountsList([...originalAmountsList]);
    setCurrentPolicyPk(policyPk);

    setCurrentCashAllocationIndex(index);
    setOriginalAmountSelectionDialog(true);
  };

  const handleCloseOriginalAmountSelectionDialog = () => {
    setOriginalAmountSelectionDialog(false);
  };

  // handle OriginalAmount Checkbox Change
  const handleOriginalAmountCheckboxChange = (currentRow) => {
    const { policy_pk, Instalment_Nbr, Installment_Agency_Amount_in_Orig, Original_Ccy, Settlement_Ccy } = currentRow;

    setCurrentPolicyPk(policy_pk);

    handleInputChange(currentCashAllocationIndex, "policy_pk", +policy_pk);

    handleInputChange(
      currentCashAllocationIndex,
      "policy_installment_number",
      +Instalment_Nbr
    );

    handleInputChange(
      currentCashAllocationIndex,
      "installment_amount_org",
      +Installment_Agency_Amount_in_Orig
    );

    handleInputChange(currentCashAllocationIndex, "original_ccy", Original_Ccy);

    handleInputChange(
      currentCashAllocationIndex,
      "settlement_currency",
      Settlement_Ccy
    );

    setOriginalAmountSelectionDialog(false);
  };

  // Add state for modal visibility
  const [isPolicyErrorModalOpen, setIsPolicyErrorModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  // Function to handle modal close
  const handleClosePolicyErrorModal = () => {
    setIsPolicyErrorModalOpen(false);
  };

  const handlePolicyIdClick = async (row) => {
    try {
      const response = await instance.get(`bankmanagement/cash_allocation_activities/${row.id}/`);
      if (response.status === 200 && response.data.message === "Success") {
        setCurrentRow(row);
        setIsConfirmationOpen(true);
      } else {
        setIsPolicyErrorModalOpen(true);
      }
    } catch (error) {
      toast.error('Error while getting details of the policy', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleConfirmNo = () => {
    setIsConfirmationOpen(false);
  };
  
  const handleConfirmYes = () => {
    // setSelectedRowIndex(-1);
    setIsEditingRecord(currentRow);
    setIsConfirmationOpen(false);
  };

  useEffect(() => {
    const index = allocations.findIndex(allocation => allocation.policy_id === policyRefNo);
    if (index !== -1 && selectedRowIndex !== index) {
        setSelectedRowIndex(index);
    }
  }, [allocations, policyRefNo]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const index = allocations.findIndex(allocation => allocation.id === parseInt(policyRefNo));
    if (index !== -1 && selectedRowIndex !== index) {
      setSelectedRowIndex(index);
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('policy_ref') !== policyRefNo) {
        searchParams.set('policy_ref', policyRefNo);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
      }
    }
  }, [allocations, policyRefNo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentPolicyRef = searchParams.get('policy_ref');
    if (currentPolicyRef !== policyRefNo) {
      searchParams.set('policy_ref', policyRefNo);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    }
  }, [policyRefNo, navigate]);

  return (
    <>
      <TableContainer
        className={`allocation-table ${singleSelect ? "single-selected" : ""}`}
      >
        <Table>
          <TableHead className="table-head" style={{ paddingRight: "10px" }}>
            <TableRow>
              <TableCell className="table-cell p-0">
                {/* <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="select-all"
                /> */}
              </TableCell>

              <TableCell className="table-cell"></TableCell>
              <TableCell className="table-cell">Policy</TableCell>
              <TableCell className="table-cell p-0">
                Allocation Status
              </TableCell>
              <TableCell className="table-cell p-0">System</TableCell>
              <TableCell className="table-cell">ROE</TableCell>
              <TableCell className="table-cell">Original Ccy</TableCell>
              <TableCell className="table-cell">Original Amt</TableCell>
              <TableCell className="table-cell">Bank Ccy</TableCell>
              <TableCell className="table-cell">Rcv Bank Amt</TableCell>
              <TableCell className="table-cell">Alloc Amt</TableCell>
              <TableCell className="table-cell">Rem Bal</TableCell>
              <TableCell className="table-cell">Batch #</TableCell>
              <TableCell className="table-cell">Cash Ref #</TableCell>
              <TableCell className="table-cell">GXB Batch #</TableCell>
              <TableCell className="table-cell">Comment</TableCell>
              <TableCell className="table-cell">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="table-body">
            {apiProgressBar ? (
              <TableRow key={1} className="table-row" style={{ height: 400 }}>
                <Box
                  style={{
                    position: "absolute",
                    left: "850px",
                    marginTop: "40px",
                  }}
                >
                  <CircularProgress
                    size={50}
                    style={{ color: "rgb(255, 90, 1)" }}
                  />
                </Box>
              </TableRow>
            ) : (
              allocations.map((row, index) => (
                <TableRow key={row.id} className="table-row" style={{ color: viewedRowIndex === index ? "inherit" : (viewedRowIndex !== null ? "#f0f0f0" : "inherit") }}>
                  <TableCell sx={{ height: "20px" }}>
                    {row?.locked === true || row?.disabled === true ? (
                      <VisibilityIcon
                        style={{
                          fontSize: 24,
                          cursor: "pointer",
                          color:
                            selectedRowIndex === index ? "#000000" : "#808080",
                          padding: "0px 5px",
                        }}
                        onClick={() => {
                          handleCheckboxChange(index, "VIEW");
                        }}
                        titleAccess={"view details"}
                      />
                    ) : (
                      <Checkbox
                        className="table-checkbox"
                        checked={selectedRowIndex === index}
                        onChange={() => handleCheckboxChange(index, "EDIT")}
                        disabled={
                          row?.locked === true || row?.disabled === true
                        }
                        style={{
                          stroke:
                            row?.locked === true || row?.disabled === true
                              ? "#808080"
                              : "#000000",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    {row.historical === true ? (
                      <div className="cash-alloc-indicator historical"></div>
                    ) : (
                      <div className="cash-alloc-indicator non-historical"></div>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextField
                      onClick={() => handlePolicyIdClick(row)}
                      value={row.policy_id}
                      sx={{
                        width: "130px",
                        padding: "0px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      className="table-input"
                      size="small"
                      disabled={handleRowFieldDisable(index)}
                      InputProps={{
                        style: { width: "140px" },
                      }}
                      // disabled={selectedIndex !== index}
                      // InputProps={{
                      //   value: row.policy_id,
                      //   onInput: (e) => {
                      //     e.preventDefault();
                      //     const value = e.target.value;
                      //     const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                      //     if (regex.test(value) || value === "") {
                      //       handleInputChange(index, "policy_id", value);
                      //     }
                      //   },
                      // }}
                    />
                    {validationStatus[index]?.policy_id && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.policy_id}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <select
                      title={row.allocation_status ?? "In Progress - CC"}
                      value={row.allocation_status ?? "In Progress - CC"}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "allocation_status",
                          e.target.value
                        )
                      }
                      style={{ ...selectStyle, WebkitTextFillColor: (index === selectedRowIndex && handleRowFieldDisable(index)) ? '#000' : 'grey' }}
                      size="small"
                      placeholder="select Here"
                      disabled={handleRowFieldDisable(index)}
                    >
                      {allocationStatusList?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {validationStatus[index]?.allocation_status && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.allocation_status}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <select
                      value={row.allocation_systemid}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "allocation_systemid",
                          e.target.value
                        )
                      }
                      style={{ ...selectStyle, maxWidth: 70, WebkitTextFillColor: (index === selectedRowIndex && handleRowFieldDisable(index)) ? '#000' : 'grey' }}
                      size="small"
                      placeholder="select Here"
                      disabled={handleRowFieldDisable(index)}
                    >
                      {systemIdList?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {validationStatus[index]?.allocation_systemid && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.allocation_systemid}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={
                        row?.bank_txn?.Bank_Currency_Code === row?.original_ccy
                          ? 1.0
                          : row?.bank_roe ?? 0
                      }
                      onChange={(e) =>
                        handleInputChange(index, "bank_roe", e.target.value)
                      }
                      onBlur={() => {
                        let roe = row?.bank_roe;
                        if (!roe) {
                          roe = "0.000000";
                        }
                        handleInputChange(
                          index,
                          "bank_roe",
                          parseFloat(roe)?.toFixed(5)
                        );
                      }}
                      size="small"
                      className="table-input"
                      disabled={handleRowFieldDisable(index)}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      type="number"
                      InputProps={{
                        style: { width: "100px" },
                        inputProps: {
                          onInput: (e) => {
                            e.preventDefault();
                            const value = e.target.value;
                            const regex = /^\d*\.?\d{0,6}$/; // Regular expression for numeric input
                            if (!regex.test(value)) return;
                            handleInputChange(index, "bank_roe", value);
                          },
                        },
                      }}
                    />
                    {validationStatus[index]?.bank_roe && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.bank_roe}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <select
                      value={row?.original_ccy}
                      onChange={(e) => {
                        handleInputChange(
                          index,
                          "original_ccy",
                          e.target.value
                        );
                        // update ROE
                        if (
                          e.target.value === row?.bank_txn?.Bank_Currency_Code
                        ) {
                          handleInputChange(index, "bank_roe", 1.0);
                        } else {
                          handleInputChange(index, "bank_roe", 0.0);
                        }
                      }}
                      style={{ ...selectStyle, maxWidth: 80, WebkitTextFillColor: (index === selectedRowIndex && handleRowFieldDisable(index)) ? '#000' : 'grey' }}
                      size="small"
                      placeholder="select Here"
                      disabled={handleRowFieldDisable(index)}
                    >
                      <option value="">Select</option>
                      {currencies?.map((option, optionIndex) => (
                        <option
                          key={`original_ccy_${option.currency_code}_${optionIndex}`}
                          value={option.currency_code}
                        >
                          {option.currency_code}
                        </option>
                      ))}
                    </select>
                    {validationStatus[index]?.original_ccy && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.original_ccy}
                      </span>
                    )}
                  </TableCell>

                  <TableCell>
                    <TextField
                      disabled={handleRowFieldDisable(index)}
                      value={row?.installment_amount_org}
                      onClick={() => {
                        if (selectedIndex === index) {
                          handleOpenOriginalAmount(
                            index,
                            row?.policy_original_amount_list,
                            row?.policy_pk
                          );
                        }
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <MoreVertIcon style={originalAmountIconStyle} />
                        ),
                      }}
                      sx={{ 
                        minWidth: "120px", 
                        maxWidth: "150px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <select
                      value={
                        row?.bank_txn?.Bank_Currency_Code === "-"
                          ? transaction?.Bank_Currency_Code
                          : row?.bank_txn?.Bank_Currency_Code
                      }
                      defaultValue={row?.bank_txn?.Bank_Currency_Code}
                      onChange={(e) => {
                        handleInputChange(index, "bank_curr", e.target.value);
                        // update ROE accordingly
                        if (e.target.value === row?.original_ccy) {
                          handleInputChange(index, "bank_roe", 1.0);
                        } else {
                          handleInputChange(index, "bank_roe", 0.0);
                        }
                      }}
                      style={{ ...selectStyle, maxWidth: 80, WebkitTextFillColor: (index === selectedRowIndex && handleRowFieldDisable(index)) ? '#000' : 'grey' }}
                      size="small"
                      placeholder="select Here"
                      disabled={handleRowFieldDisable(index)}
                    >
                      <option value="">Select</option>
                      {currencies.map((option, optionIndex) => (
                        <option
                          key={`bank_curr_${option.currency_code}_${optionIndex}`}
                          value={option.currency_code}
                        >
                          {option.currency_code}
                        </option>
                      ))}
                    </select>
                    {validationStatus[index]?.bank_currency && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.bank_currency}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row?.receivable_amt}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "receivable_amt",
                          e.target.value
                        )
                      }
                      onClick={() =>
                        handleInputChange(
                          index,
                          "receivable_amt",
                          row?.receivable_amt?.split(".00")[0]
                        )
                      }
                      onBlur={() => {
                        let recAmount = row?.receivable_amt;
                        if (!recAmount) {
                          recAmount = 0;
                        }
                        handleInputChange(
                          index,
                          "receivable_amt",
                          parseFloat(recAmount)?.toFixed(2)
                        );
                      }}
                      sx={{ 
                        width: "100px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      size="small"
                      className="table-input"
                      disabled={handleRowFieldDisable(index)}
                      type="number" // Set the input type to "number"
                      InputProps={{
                        style: { minWidth: "120px", maxWidth: "140px" },
                        inputProps: {
                          min: 0, // Set minimum value to 0 if needed
                          max: 10 ** 8,
                          style: { textAlign: "right" },
                          onInput: (e) => {
                            e.preventDefault();
                            const value = e.target.value;
                            const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                            if (regex.test(value)) {
                              handleInputChange(index, "receivable_amt", value);
                            }
                          },
                        },
                      }}
                    />
                    {validationStatus[index]?.receivable_amt && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.receivable_amt}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row?.allocated_amt}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "allocated_amt",
                          e.target.value
                        )
                      }
                      onClick={() =>
                        handleInputChange(
                          index,
                          "allocated_amt",
                          row?.allocated_amt?.split(".00")[0]
                        )
                      }
                      onBlur={() => {
                        let allAmount = row?.allocated_amt;
                        if (!allAmount) {
                          allAmount = 0;
                        }
                        handleInputChange(
                          index,
                          "allocated_amt",
                          parseFloat(allAmount)?.toFixed(2)
                        );
                      }}
                      sx={{ 
                        width: "100px", 
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      size="small"
                      className="table-input"
                      disabled={handleRowFieldDisable(index)}
                      type="number" // Set the input type to "number"
                      InputProps={{
                        style: { minWidth: "120px", maxWidth: "140px" },
                        inputProps: {
                          min: 0, // Set minimum value to 0 if needed
                          max: 10 ** 8,
                          style: { textAlign: "right" },
                          onInput: (e) => {
                            e.preventDefault();
                            const value = e.target.value;
                            const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                            if (regex.test(value)) {
                              handleInputChange(index, "allocated_amt", value);
                            }
                          },
                        },
                      }}
                    />
                    {validationStatus[index]?.allocated_amt && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.allocated_amt}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextField
                      value={parseFloat(
                        row?.receivable_amt - row?.allocated_amt
                      )?.toFixed(2)}
                      sx={{ 
                        width: "80px", 
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      size="small"
                      className="table-input"
                      disabled={true}
                      type="number"
                      InputProps={{
                        inputProps: {
                          style: { textAlign: "right" },
                        },
                        style: { minWidth: "120px", maxWidth: "140px" },
                      }}
                    />
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextField
                      value={row.XFIbatchno}
                      sx={{
                        width: "80px",
                        padding: "0px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      className="table-input"
                      size="small"
                      disabled={handleRowFieldDisable(index) || userData?.role?.toLowerCase() !== "manager"}
                      InputProps={{
                        style: { width: "100px" },
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          handleInputChange(index, "XFIbatchno", value);
                          // const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                          // if (regex.test(value) || value === "") {
                          //   handleInputChange(index, "XFIbatchno", value);
                          // }
                        },
                      }}
                    />
                    {validationStatus[index]?.XFIbatchno && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.XFIbatchno}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextField
                      value={row.cash_reference}
                      sx={{
                        width: "152px",
                        padding: "0px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      className="table-input"
                      size="small"
                      disabled={handleRowFieldDisable(index) || userData?.role?.toLowerCase() !== "manager"}
                      InputProps={{
                        style: { width: "90px" },
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          handleInputChange(index, "cash_reference", value);
                        },
                      }}
                    />
                    {validationStatus[index]?.cash_reference && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.cash_reference}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextField
                      value={row.GXPbatchno}
                      sx={{
                        width: "90px",
                        padding: "0px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      className="table-input"
                      size="small"
                      disabled={handleRowFieldDisable(index) || userData?.role?.toLowerCase() !== "manager"}
                      InputProps={{
                        style: { width: "90px" },
                        onInput: (e) => {
                          e.preventDefault();
                          const value = e.target.value;
                          handleInputChange(index, "GXPbatchno", value);
                          // const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                          // if (regex.test(value) || value === "") {
                          // }
                        },
                      }}
                    />
                    {validationStatus[index]?.GXPbatchno && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.GXPbatchno}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextField
                      value={row.comment}
                      sx={{
                        width: "130px",
                        padding: "0px",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: index === selectedRowIndex ? 'black' : "grey",
                        },
                      }}
                      className="table-input"
                      size="small"
                      disabled={handleRowFieldDisable(index)}
                      InputProps={{
                        style: { width: "70px" },
                        endAdornment: (
                          <MoreHorizIcon
                            onClick={() =>
                              handleCommentsOpenDialog(index, row.comment)
                            }
                          />
                        ),
                        // onInput: (e) => {
                        //   e.preventDefault();
                        //   const value = e.target.value;
                        //   handleInputChange(index, "cash_reference", value);
                        // },
                      }}
                    />
                    {validationStatus[index]?.cash_reference && (
                      <span style={{ color: "red" }}>
                        {validationStatus[index]?.cash_reference}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    <Button
                      id={`demo-positioned-button-${row.id}`}
                      aria-controls={`demo-positioned-menu-${row.id}`}
                      aria-haspopup="true"
                      aria-expanded={"true"}
                      onClick={(e) => handleClick(e, row.id)}
                      className="action-button pill"
                      disabled={row?.locked === true}
                    >
                      <MoreHorizIcon />
                    </Button>
                    {menuId === row.id && (
                      <Menu
                        id={`demo-positioned-menu-${row.id}`}
                        aria-labelledby={`demo-positioned-button-${row.id}`}
                        anchorEl={anchorEl}
                        open
                        className="cash-allocation-table-action-menu"
                        onClose={() => handleClose("")}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {policyActions.map(
                          (item) =>
                            shouldShow(item, row) && (
                              <MenuItem
                                key={item.id}
                                className="cash-allocation-table-action-item"
                                onClick={() => handleClose(item.id, row)}
                              >
                                {item.label}
                                <ArrowForwardIosIcon
                                  className="cash-allocation-table-action-icon"
                                  fontSize="small"
                                />
                              </MenuItem>
                            )
                        )}
                      </Menu>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openCommentsDialog} onClose={handleCloseDialogs}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogCommentsValue}
            onChange={handleDialogInputChange}
            className="PaymentReferenceDialogue"
            disabled={currentCommentsIndex != selectedRowIndex}
          />
        </DialogContent>

        {currentCommentsIndex == selectedRowIndex && (
          <DialogActions>
            <CommonButton
              text="Cancel"
              handleClick={handleCloseDialogs}
              sx={{
                backgroundColor: "#ffff",
                color: "#000000",
                border: "1px solid #BCBDBF",
              }}
            />
            <CommonButton
              text="Save"
              handleClick={handleSaveComments}
              hoverColor="#FF5A01"
              disabled={!dialogCommentsValue}
            />
          </DialogActions>
        )}
      </Dialog>
      <DialogueContainer
        id={openDialogueId}
        allocation={dialogueAllocation}
        transaction={transaction}
        onClose={handleClose}
        currencies={currencies}
      />
      {singleSelect && (
        <AllocationDetails
          policy={allocations[selectedIndex]}
          handleClose={cancelSelection}
          transaction={transaction}
          handleSave={saveSelection}
          currentRowMode={currentRowMode}
        />
      )}

      <Dialog
        open={originalAmountSelectionDialog}
        onClose={handleCloseOriginalAmountSelectionDialog}
        maxWidth={"990px"}
      >
        <DialogTitle>Select Original Amount</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TableContainer className={"allocation-table"}>
            <Table>
              <TableHead className="table-head">
                <TableRow
                  style={{
                    backgroundColor: "#ffc000",
                  }}
                >
                  <TableCell className="table-cell p-0"></TableCell>

                  <TableCell className="table-cell" style={{ fontSize: 14 }}>ID</TableCell>
                  <TableCell className="table-cell" style={{ fontSize: 14 }}>
                    Installment Number
                  </TableCell>
                  <TableCell className="table-cell" style={{ fontSize: 14 }}>
                    Installment Original Amount
                  </TableCell>
                  <TableCell className="table-cell pr-5" style={{ fontSize: 14 }}>
                    Original Currency
                  </TableCell>
                  <TableCell className="table-cell pr-5" style={{ fontSize: 14 }}>
                    Settlement Currency
                  </TableCell>
                  <TableCell className="table-cell pr-5" style={{ fontSize: 14 }}>
                    Installment Due Date
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="table-body">
                {/* <RadioGroup
                  name="select-original-amount"
                  value={selectedExcelSheetName}
                  onChange={(e) => setSelectedExcelSheetName(e.target.value)}
                >
                  {currentOriginalAmountsList?.map((row, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={sheetName}
                        control={<Radio />}
                        label={sheetName}
                      />
                    );
                  })}
                </RadioGroup> */}

                {currentOriginalAmountsList.map((row, index) => (
                  <TableRow key={row.id} className="table-row">
                    <TableCell sx={{ height: "40px" }}>
                      <Radio
                        name="select-original-amount"
                        className="table-checkbox"
                        defaultChecked={false}
                        checked={
                          Number(currentPolicyPk) === Number(row?.policy_pk)
                        }
                        onChange={(e) =>
                          handleOriginalAmountCheckboxChange(row)
                        }
                      />
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      <FormLabel>{row?.policy_pk}</FormLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <FormLabel>{row?.Instalment_Nbr}</FormLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <FormLabel>
                        {row?.Installment_Agency_Amount_in_Orig}
                      </FormLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <FormLabel>{row?.Original_Ccy}</FormLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <FormLabel>{row?.Settlement_Ccy}</FormLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <FormLabel>{row?.Installment_Due_date}</FormLabel>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          {/* <CommonButton
            text="Cancel"
            handleClick={handleCloseOriginalAmountSelectionDialog}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          /> */}
          {/* <CommonButton
            text="Update"
            handleClick={handleUpdateOriginalAmountSelection}
            hoverColor="#FF5A01"
          /> */}
        </DialogActions>
      </Dialog>
      {/* Add the Dialog component for the policy error modal */}
      <Dialog
        open={isPolicyErrorModalOpen}
        onClose={handleClosePolicyErrorModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Policy cannot be updated as there are activities associated to it.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
            Kindly check !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePolicyErrorModal} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog */}
      <Dialog
        open={isConfirmationOpen}
        onClose={handleConfirmNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning !!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to update the policy number? If yes, all the data will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmNo} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmYes} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllocationsTable;
