import { FormLabel, Grid, TextField } from "@mui/material";
import { CommonSelect } from "../common";


export const ViewAndEditEntity = ({

  handleInputChange,
  index,
  row,
  viewMode,
  validationStatus,
  allocationStatus
}) => {
  //inline style
  const InlineStyle = {
    EntityDivison: {
      width: "230px", height: "32", padding: "0px"
    },
  }
  return (
    <form>
      <Grid container justifyContent={"space-between"}>
        <Grid
          item
          xs={4}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Correction Type</FormLabel>
          <TextField
            value={row.correction_type}
            onChange={(e) =>
              handleInputChange(index, "correction_type", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.correction_type && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.correction_type}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Correction Description</FormLabel>
          <TextField
            value={row.correction_description}
            onChange={(e) =>
              handleInputChange(index, "correction_description", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.correction_description && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.correction_description}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Allocation Status</FormLabel>
          <CommonSelect
            disabled={viewMode}
            handleChange={(event, value, reason) =>
              handleInputChange(
                index,
                'allocation_status',
                value,
                reason,
                event
              )
            }
            name="Allocation Status"
            // Map through your list of dropdown values
            options={allocationStatus?.map((option) => option.allocation_status)}
            placeholder="Select Currency"
            value={row.allocation_status}
          />
          {validationStatus[index]?.allocation_status && (
            <span style={{ color: 'red' }}>{validationStatus[index]?.allocation_status}</span>
          )}
        </Grid>

      </Grid>
    </form>
  );
}