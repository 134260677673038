import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../utils/config';
import axios from 'axios';
import Cookies from 'js-cookie';

const initialState = {
    isLoggedIn: false,
    role: "",
    status: "",
    userId: "",
    username: "",
    isLoading: false,
    loginError: null,
}

export const loginUser = createAsyncThunk(
    'login/User',
    async (loginData, { rejectWithValue }) => {
        try {
            const res = await axios.post(API_URL + "users/login/", loginData)
            const data = await res.data
            return data;
        } catch (error) {
            const errorMessage = error?.response?.data?.detail ?? error?.message;
            return rejectWithValue(errorMessage);
        }
    }
)

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.isLoading = true
            state.isLoggedIn = false
        })
        builder.addCase(loginUser.fulfilled, (state, action) => {
            Cookies.set('refreshToken', action.payload.refresh, { expires: 1 });
            Cookies.set('accessToken', action.payload.access, { expires: 1 / 288 });
            Cookies.set('isLoggedIn', true);
            state.isLoading = false
            state.isLoggedIn = true
            state.loginError = null
            state.role = action.payload.role;
            state.status = action.payload.status;
            state.userId = action.payload.user_id;
            state.username = action.payload.username;
            localStorage.setItem("userID", action.payload.user_id)
        })
        builder.addCase(loginUser.rejected, (state, action) => {
            state.isLoading = false
            state.loginError = action?.payload
        })
    },
})

export default loginSlice.reducer