import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { inputStyleObj } from "./WriteOff";
import { parseAllocationDate, parseAllocationMonth } from "./utils";

const History = ({ fixedDetails }) => {
  const history = [
    {
      reason: "Test",
      request_date: "Test",
      approver_name: "Test",
      allocated_amt: "Test",
      comment: "Test",
    },
  ];
  return (
    <div className="cash-allocation-dialogue-container">
      <Grid container>
        {fixedDetails.map(({ label, value }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            key={label}
            paddingLeft={i % 2 ? 2 : 0}
            paddingRight={i % 2 ? 0 : 2}
            marginBottom={2}
          >
            <span>{label} </span>
            <span style={{ fontWeight: "bold" }}>: {value}</span>
          </Grid>
        ))}
      </Grid>
      <TableContainer sx={{ height: 275, overflow: "auto" }}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="table-cell">Write off Reason</TableCell>
              <TableCell className="table-cell p-0">Request Date</TableCell>
              <TableCell className="table-cell">Approver Name</TableCell>
              <TableCell className="table-cell">Amount</TableCell>
              <TableCell className="table-cell">Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {history.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell className="table-cell">{row.reason}</TableCell>
                <TableCell className="table-cell">{row.request_date}</TableCell>
                <TableCell className="table-cell">
                  {row.approver_name}
                </TableCell>
                <TableCell className="table-cell">
                  {row.allocated_amt}
                </TableCell>
                <TableCell className="table-cell">{row.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const MSDUpdate = ({ allocation, handleClose }) => {
  const [formData, setFormData] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const fixedDetails = useMemo(
    () => [
      {
        label: "Policy#",
        value: allocation?.policy_id,
      },
      {
        label: "Approved Date",
        value: parseAllocationDate(allocation?.allocation_date),
      },
      {
        label: "System",
        value: allocation?.allocation_systemid,
      },
      {
        label: "Accounting Month",
        value: parseAllocationMonth(allocation?.accounting_monthyear),
      },
    ],
    [allocation]
  );
  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    handleClose("");
  }, [setIsDialogOpen]);

  const handleSubmitAssing = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  const handleInputChange = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
      <DialogTitle style={{ background: "#FCFCFC" }}>MSD Update</DialogTitle>
      <DialogContent>
        {showHistory ? (
          <History
            setShowHistory={setShowHistory}
            fixedDetails={fixedDetails}
          />
        ) : (
          <>
            <Grid container>
              {fixedDetails.map(({ label, value }, i) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={label}
                  paddingLeft={i % 2 ? 2 : 0}
                  paddingRight={i % 2 ? 0 : 2}
                  marginBottom={2}
                >
                  <span>{label} </span>
                  <span style={{ fontWeight: "bold" }}>: {value}</span>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} paddingRight={2}>
                <p style={{ fontSize: 13, color: "#17191B" }}>
                  Write off Reason
                </p>

                <Input
                  style={{ ...inputStyleObj }}
                  placeholder="Write details here"
                  name="reason"
                  value={formData.reason}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6} paddingLeft={2}>
                <p style={{ fontSize: 13, color: "#17191B" }}>Request Date</p>

                <Input
                  type="date"
                  style={{ ...inputStyleObj }}
                  name="request_date"
                  value={formData.request_date}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6} paddingRight={2}>
                <p style={{ fontSize: 13, color: "#17191B" }}>Approver name</p>

                <Input
                  style={inputStyleObj}
                  placeholder="Write details here"
                  name="approver_name"
                  value={formData.approver_name}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} md={6} paddingLeft={2}>
                <p style={{ fontSize: 13, color: "#17191B" }}>Amount</p>

                <Input
                  style={{ ...inputStyleObj }}
                  placeholder="Write details here"
                />
              </Grid>

              <Grid item xs={12} md={6} paddingRight={2}>
                <p style={{ fontSize: 13, color: "#17191B" }}>Comments</p>

                <TextareaAutosize
                  // style={inputStyleObj}
                  style={{
                    height: 50,
                    width: "100%",
                  }}
                  placeholder="Write details here"
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          onClick={() => setShowHistory(!showHistory)}
          color="inherit"
          variant="outlined"
          sx={{ borderRadius: 100 }}
        >
          History
        </Button>
        <Button
          onClick={handleCloseDialog}
          color="inherit"
          variant="outlined"
          sx={{ borderRadius: 100 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmitAssing}
          variant="contained"
          color="warning"
          sx={{ borderRadius: 100 }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MSDUpdate;
