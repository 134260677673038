import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  pagination: {
    display: "flex",
    gap: "12px",
    right: "24px",
    marginRight: "10px",
    flexDirection: ({ direction }) =>
      direction === "rtl" ? "row-reverse" : "row",
    "& .iconify": {
      transform: ({ direction }) =>
        direction === "rtl" ? "rotate(180deg)" : "rotate(0deg)",
    },
  },
  arrowBtn: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px",
    outline: "none",
    border: "1px solid rgba(51, 48, 60, 0.22)",
    cursor: "pointer",
    color: "rgba(51, 48, 60, 0.87)",
    background: "white",
    "&:disabled": {
      cursor: "no-drop",
      background: "#ececec",
    },
  },
  input: {
    height: "27px",
    borderRadius: "6px",
    outline: "none",
    border: "1px solid rgba(51, 48, 60, 0.22)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    color: "black",
    background: "#ffffff",
    "&:disabled": {
      cursor: "no-drop",
      background: "#ececec",
      color: "#000000",
    },
  },
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "30px",
    fontSize: "15px",
    lineHeight: "22px",
    color: "rgba(51, 48, 60, 0.87)",
  },
  countBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px",
    outline: "none",
    border: "1px solid #D9D9D9",
    cursor: "no-drop",
    padding: "0 10px",
    color: "black",
    background: "#ffffff",
    "&:disabled": {
      cursor: "no-drop",
      background: "#ececec",
    },
  },
});
