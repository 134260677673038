import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API_URL from '../api'


const initialState = {
    divisions: [],
    isLoading: false,
    error: null,
}

export const getDivisions = createAsyncThunk(
    'divisions/List',
    async () => {
        const res = await API_URL.get("documents/entity/")
        const data = await res.data
        return data
    }
)

export const divisionsSlice = createSlice({
    name: 'divisions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDivisions.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getDivisions.fulfilled, (state, action) => {
            state.isLoading = false
            state.divisions = action.payload
            state.error = null
        })
        builder.addCase(getDivisions.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
    },
})

export default divisionsSlice.reducer