import { FormLabel, Grid, TextField } from "@mui/material";


export const ViewAndEditEntity = ({
  
    handleInputChange,
    index,
    row,
    viewMode,
    validationStatus,
}) => {
    //inline style
    const InlineStyle = {
        EntityDivison: {
            width: "230px", height: "32", padding: "0px"
        },
    }
    return (
      <form>
        <Grid container justifyContent={"space-between"}>
          <Grid
            item
            xs={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Cash Transfer</FormLabel>
            <TextField
              value={row.cash_transfer_value}
              onChange={(e) =>
                handleInputChange(index, "cash_transfer_value", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.cash_transfer_value && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.cash_transfer_value}
              </span>
            )}
          </Grid>
      
        </Grid>
      </form>
    );
}