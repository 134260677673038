import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { inputStyleObj } from "./WriteOff";
import { acctMonthToLabel, useCfi } from "./hooks";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UpdateGenericWorkflowDialog from "../Workflow/UpdateGenericWorkflowDialog";
import { customDateFormat } from "./utils";

const History = ({ fixedDetails, history }) => {
  const [
    openUpdateGenericCfiWorkflowDialog,
    setOpenUpdateGenericCfiWorkflowDialog,
  ] = useState(false);
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");
  const [currentTxnWorkflowDetails, setCurrentTxnWorkflowDetails] = useState(
    {}
  );
  const handleUpdateGenericWorkflowDialog = () => {
    setOpenUpdateGenericCfiWorkflowDialog(false);
  };

  return (
    <div className="cash-allocation-dialogue-container">
      <Grid container>
        {fixedDetails.map(({ label, value }, i) => (
          <Grid
            item
            xs={12}
            md={6}
            key={label}
            paddingLeft={i % 2 ? 2 : 0}
            paddingRight={i % 2 ? 0 : 2}
            marginBottom={2}
          >
            <span style={{ fontWeight: label === "Policy#" ? 800 : 400 }}>
              {label}
            </span>
            <span style={{ fontWeight: "bold" }}>: {value}</span>
          </Grid>
        ))}
      </Grid>
      <TableContainer sx={{ height: 275, overflow: "auto" }}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                className="table-cell p-0"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                New Allocation Date
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Changes
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Impact
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Amt
              </TableCell>
              <TableCell
                className="table-cell p-0"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Ccy
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Date Process in system
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                WF Status
              </TableCell>
              <TableCell
                className="table-cell"
                sx={{ fontSize: "15px", fontWeight: 800 }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {history.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell className="table-cell">
                  {customDateFormat(row?.request_date)}
                </TableCell>
                <TableCell className="table-cell">{row?.cfi_change}</TableCell>
                <TableCell className="table-cell">{row?.cfi_impact}</TableCell>
                <TableCell className="table-cell">{row?.cfi_amt}</TableCell>
                <TableCell className="table-cell">{row?.cfi_curr}</TableCell>
                <TableCell className="table-cell">
                  {row?.process_date}
                </TableCell>
                <TableCell className="table-cell">
                  {row?.workflow?.workflow_status}
                </TableCell>
                <TableCell className="table-cell">
                  <IconButton>
                    <SettingsTwoToneIcon
                      onClick={() => {
                        setCurrentWorkflowName(
                          row?.workflow?.workflow_json_data?.workflow_name ?? ""
                        );
                        setCurrentTxnWorkflowDetails(row?.workflow ?? {});
                        setOpenUpdateGenericCfiWorkflowDialog(true);
                      }}
                      sx={{ fontSize: "25px", color: "#3b3f44" }}
                    />
                  </IconButton>
                  <IconButton>
                    <FileDownloadOutlinedIcon
                      sx={{ fontSize: "25px", color: "blue" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openUpdateGenericCfiWorkflowDialog && (
        <UpdateGenericWorkflowDialog
          openUpdateGenericWorkflowDialog={openUpdateGenericCfiWorkflowDialog}
          handleUpdateGenericWorkflowDialog={handleUpdateGenericWorkflowDialog}
          workflowName={currentWorkflowName}
          currentWorkflowDetails={currentTxnWorkflowDetails}
        />
      )}
    </div>
  );
};

const CFI = ({ allocation, handleClose }) => {
  const { history, formData, setFormData, submit,isApiInProgress } = useCfi(allocation);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [showHistory, setShowHistory] = useState(true);

  const fixedDetails = useMemo(
    () => [
      {
        label: "Accounting Month",
        value: allocation?.accounting_monthyear
          ? acctMonthToLabel(allocation?.accounting_monthyear)
          : "",
      },
      {
        label: "Txn Date",
        value: customDateFormat(allocation?.bank_txn?.Payment_Receive_Date),
      },
      {
        label: "System",
        value: allocation?.system_id,
      },
      {
        label: "Bank A/c#",
        value: allocation?.bank_reconciliation?.bank_details?.account_number,
      },
      {
        label: "Status",
        value: allocation?.allocation_status,
      },
      {
        label: "Receivable Amt",
        value: allocation?.bank_txn?.Receivable_Amount,
      },
    ],
    [allocation]
  );

  useEffect(() => {
    setShowHistory(!!history.length);
  }, [history?.length]);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    handleClose("");
  }, [setIsDialogOpen]);

  const handleSubmitAssing = useCallback(
    async (e) => {
      e.preventDefault();
      await submit();
      setShowHistory(true);
      setFormData("");
    },
    [setIsDialogOpen, submit]
  );

  const handleInputChange = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    },
    [setFormData]
  );

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "800px",
        },
      }}
    >
      <form onSubmit={handleSubmitAssing}>
        <DialogTitle
          style={{
            background: "#FCFCFC",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "70px",
          }}
        >
          <Typography sx={{ fontSize: 20 }}>CFI</Typography>
          <Typography
            sx={{ fontSize: 15, fontWeight: 750, marginLeft: "50px" }}
          >
            Policy#: {allocation?.policy_id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {showHistory ? (
            <History
              setShowHistory={setShowHistory}
              fixedDetails={fixedDetails}
              history={history}
            />
          ) : (
            <>
              <Grid container>
                {fixedDetails.map(({ label, value }, i) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={label}
                    paddingLeft={i % 2 ? 2 : 0}
                    paddingRight={i % 2 ? 0 : 2}
                    marginBottom={2}
                  >
                    <span>{label} </span>
                    <span style={{ fontWeight: "bold" }}>: {value}</span>
                  </Grid>
                ))}
              </Grid>

              <Grid container>
                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>Amount</p>

                  <Input
                    style={{ ...inputStyleObj }}
                    placeholder="Enter amount here"
                    name="cfi_amt"
                    value={formData.cfi_amt}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.target.value = formData.cfi_amt || "";
                        return;
                      }
                      handleInputChange(e);
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} paddingLeft={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>What Changed</p>

                  <TextareaAutosize
                    style={{
                      height: 50,
                      width: "100%",
                    }}
                    name="cfi_change"
                    value={formData.cfi_change}
                    onChange={handleInputChange}
                    placeholder="Write details here"
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6} paddingRight={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Impact to prior allocation
                  </p>

                  <Input
                    style={{ ...inputStyleObj }}
                    placeholder="Write details here"
                    name="cfi_impact"
                    value={formData.cfi_impact}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} paddingLeft={2}>
                  <p style={{ fontSize: 13, color: "#17191B" }}>
                    Date Process in System
                  </p>

                  <Input
                    type="date"
                    style={{ ...inputStyleObj }}
                    name="process_date"
                    value={formData.process_date}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => setShowHistory(!showHistory)}
            color={showHistory ? "warning" : "inherit"}
            variant={showHistory ? "contained" : "outlined"}
            sx={{ borderRadius: 100 }}
          >
            {showHistory ? "Add" : "History"}
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="inherit"
            variant="outlined"
            sx={{ borderRadius: 100, border: "1px solid #BCBDBF" }}
          >
            Close
          </Button>
          {!showHistory && (
            <Button
              variant="contained"
              color="warning"
              sx={{ borderRadius: 100 }}
              type="submit"
              disabled ={isApiInProgress}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CFI;
