import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";


//inline styles
const InlineStyles = {
    TableHeader: {
        backgroundColor: '#262671',
        color: "white",
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: "50px"
    },
    TableCells: {
        color: 'black',
        fontSize: '12px',
        height: '44px',
        padding: '0px'
    },
    TableBodyRows: {
        border: '1px solid #ddd', marginTop: "10px", paddingTop: "15px", verticalAlign: "top"
    },
    TableBodyRowCells: {
        height: '20px', padding: '0px'
    },
    PaymentReceiveDate: {
        width: "100px", height: "33px", padding: "0px"
    },
    BrokerBranchTableCell: {
        height: "20px", padding: "3px"
    },
    ReceivableAmountTableCell: {
        height: "20px", padding: "3px"
    },
    PaymentCurrencyCodeTableCell: {
        height: "20px", padding: "3px"
    },
    BankExchangeRateTableCell: {
        height: "20px", padding: "3px"
    },
    BankExchangeChargesTableCell: {
        height: "20px", padding: "3px"
    },
    entity_divisionsTableCell: {
        height: "20px", padding: "3px"
    },
    entity_divisions: {
        position: "relative", width: "123px", height: "32"
    },
    MoreVertIconStyle: {
        color: 'FF5A01',
        background: 'transparent',
        cursor: 'pointer',
        transform: 'rotate(90deg)'
    },
    BankChargesTableCell: {
        height: "20px", padding: "3px"
    }

}
export const AddEntityDetails = ({

    selectAll,
    handleSelectAll,
    handleInputChange,
    handleCheckboxChange,
    rows,
    viewMode,
    validationStatus,
    handleentity_divisionsOpenDialog,
    selectedRows,
}) => {

    return (
        <Table>
            <TableHead
                style={InlineStyles.TableHeader}>
                <TableRow style={{ backgroundColor: "#FFC000" }}>
                    <TableCell style={InlineStyles.TableCells}>
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            style={{ color: "black" }}
                        />
                    </TableCell>
                    <TableCell className='TableCell'>Entity Divisions</TableCell>
                    <TableCell className='TableCell'>Entity Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index} style={InlineStyles.TableBodyRows}>
                        <TableCell sx={InlineStyles.TableBodyRowCells}>
                            <Checkbox
                                checked={selectedRows[index] || false}
                                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            />
                        </TableCell>
                    
                        <TableCell sx={InlineStyles.entity_divisionsTableCell}>
                            <TextField
                                value={row.entity_divisions}
                                onChange={(e) => handleInputChange(index, 'entity_divisions', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.entity_divisions && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.entity_divisions}</span>
                            )}
                        </TableCell>

                        <TableCell sx={InlineStyles.entity_divisionsTableCell}>
                            <TextField
                                value={row.entity_name}
                                onChange={(e) => handleInputChange(index, 'entity_name', e.target.value)}
                                sx={InlineStyles.entity_divisions}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                              
                            /><br />
                            {validationStatus[index]?.entity_name && (
                                <span style={{ color: 'red' }}>{validationStatus[index]?.entity_name}</span>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}