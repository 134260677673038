import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";


//inline styles
const InlineStyles = {
  TableHeader: {
    backgroundColor: '#262671',
    color: "white",
    position: 'sticky',
    top: 0,
    zIndex: 1,
    height: "50px"
  },
  TableCells: {
    color: 'black',
    fontSize: '12px',
    height: '44px',
    padding: '0px'
  },
  TableBodyRows: {
    border: '1px solid #ddd', marginTop: "10px", paddingTop: "15px", verticalAlign: "top"
  },
  TableBodyRowCells: {
    height: '20px', padding: '0px'
  },
  PaymentReceiveDate: {
    width: "100px", height: "33px", padding: "0px"
  },
  BrokerBranchTableCell: {
    height: "20px", padding: "3px"
  },
  ReceivableAmountTableCell: {
    height: "20px", padding: "3px"
  },
  PaymentCurrencyCodeTableCell: {
    height: "20px", padding: "3px"
  },
  BankExchangeRateTableCell: {
    height: "20px", padding: "3px"
  },
  BankExchangeChargesTableCell: {
    height: "20px", padding: "3px"
  },
  entity_divisionsTableCell: {
    height: "20px", padding: "3px"
  },
  entity_divisions: {
    position: "relative", width: "123px", height: "32"
  },
  MoreVertIconStyle: {
    color: 'FF5A01',
    background: 'transparent',
    cursor: 'pointer',
    transform: 'rotate(90deg)'
  },
  BankChargesTableCell: {
    height: "20px", padding: "3px"
  }

}
export const AddBankExchange = ({

  selectAll,
  handleSelectAll,
  handleInputChange,
  handleCheckboxChange,
  rows,
  viewMode,
  validationStatus,
  handleentity_divisionsOpenDialog,
  selectedRows,
  handleBlur
}) => {

  return (
    <Table>
      <TableHead
        style={InlineStyles.TableHeader}>
        <TableRow style={{ backgroundColor: "#FFC000" }}>
          <TableCell style={InlineStyles.TableCells}>
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ color: "black" }}
            />
          </TableCell>
          <TableCell className='TableCell'>Month</TableCell>
          <TableCell className='TableCell'>Currency Code </TableCell>
          <TableCell className='TableCell'>Exchange Rate</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index} style={InlineStyles.TableBodyRows}>
            <TableCell sx={InlineStyles.TableBodyRowCells}>
              <Checkbox
                checked={selectedRows[index] || false}
                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
              />
            </TableCell>
            <TableCell sx={{ padding: "3px" }}>
              <input
                type="date"
                value={
                  row.month
                    ? row.month.substring(0, 10)
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "month",
                    e.target.value
                  )
                }
                style={InlineStyles.PaymentReceiveDate}
                placeholder="Enter Details Here"
                disabled={viewMode}
              />
              <br />
              {validationStatus[index]?.month && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.month}
                </span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.binding_aggrement_type}>
              <TextField
                value={row.currency_code}
                onChange={(e) => handleInputChange(index, 'currency_code', e.target.value)}
                sx={InlineStyles.entity_divisions}
                size="small"
                placeholder='Enter Details Here'
                disabled={viewMode}

              /><br />
              {validationStatus[index]?.currency_code && (
                <span style={{ color: 'red' }}>{validationStatus[index]?.currency_code}</span>
              )}
            </TableCell>
            <TableCell sx={InlineStyles.BankExchangeChargesTableCell}>
              <TextField
                value={row.exchange_rate}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "exchange_rate",
                    e.target.value
                  )
                }
                // onBlur={(e) =>
                //   handleBlur(index, "exchange_rate", e.target.value)
                // }
                sx={{ width: "100px" }}
                size="small"
                type="number" // Set the input type to "number"
                InputProps={{
                  inputProps: {
                    min: 0, // Set minimum value to 0 if needed
                    onInput: (e) => {
                      e.preventDefault();
                      const value = e.target.value;
                      const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                      if (regex.test(value) || value === "") {
                        handleInputChange(
                          index,
                          "exchange_rate",
                          value
                        );
                      }
                    },
                  },
                }}
                disabled={viewMode}
              /><br />
              {validationStatus[index]?.exchange_rate && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.exchange_rate}
                </span>
              )}
            </TableCell>


          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}