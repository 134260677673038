import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    manageUser: {}
}

export const manageUserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserData: (state, action) => {
            state.manageUser = action.payload
        }
    },
})

export const { updateUserData } = manageUserSlice.actions
export default manageUserSlice.reducer